import { Text } from '@/components/core/Text/Text';
import { Divider } from '@/components/shared/Categories/Categories';
import { CalendarIcon } from '@/icons/CalendarIcon';
import { PenIcon } from '@/icons/PenIcon';
import { FC } from '@/interfaces/blocks/shared';
import { sva } from '@/styled-system/css';
import { formatPublicationDateToEuZurich } from '@/utils/i18n/locale-formatting/localeFormatting';
import { TranslationLabelValues, translate } from '@/utils/i18n/translation-labels/translationLabels';

const articlePublicationInfoRecipe = sva({
	slots: ['root', 'divider', 'iconText'],
	base: {
		root: {
			display: 'flex',
			color: 'text.regular.subtitle',
			gap: 0,
		},
		iconText: {
			display: 'flex',
			gap: '6px',
		},
	},
	variants: {
		type: {
			teaser: {
				root: {
					flexDirection: {
						base: 'column',
						md: 'row',
					},
					alignItems: 'flex-start',
				},
				divider: {
					hideBelow: 'md',
				},
			},
			articlePage: {
				root: {
					base: {
						flexDirection: 'column',
						gap: 2,
					},
					sm: {
						mb: '22px',
					},
					md: {
						flexDirection: 'row',
						gap: 0,
						mb: '22px',
					},
					lg: {
						mb: 0,
					},
				},
				divider: {
					hideBelow: 'md',
					p: 2,
					display: 'flex',
					alignItems: 'center',
				},
				iconText: {
					'& p': {
						fontSize: '15px',
						lineHeight: '150%',
					},
				},
			},
			articleAuthors: {
				root: {
					flexDirection: 'column',
					mb: '65px',
					gap: 2,
				},
				divider: {
					display: 'none',
				},
				iconText: {
					'& p': {
						fontSize: '15px',
						lineHeight: '150%',
					},
				},
			},
		},
	},
});

interface IArticlePublicationInfoProps {
	articleTranslations: TranslationLabelValues;
	timezoneTranslations: TranslationLabelValues;
	showRevisedDateInsteadOfPublishDate?: boolean;
	revisedDate?: string;
	publicationDate?: string;
	includePrefixes?: boolean;
	textSize?: 'sm' | 'xs';
	type?: 'teaser' | 'articlePage' | 'articleAuthors';
}

export const ArticlePublicationInfo: FC<IArticlePublicationInfoProps> = ({
	articleTranslations,
	timezoneTranslations,
	revisedDate,
	publicationDate,
	showRevisedDateInsteadOfPublishDate,
	includePrefixes = true,
	textSize = 'sm',
	type = 'teaser',
}) => {
	const publishedOnPrefix = translate(articleTranslations, 'article.page.publishedOn');
	const updatedAtPrefix = translate(articleTranslations, 'article.page.updatedAt');

	const publicationDateObj = publicationDate ? new Date(publicationDate) : undefined;
	const revisedDateObj = revisedDate ? new Date(revisedDate) : undefined;

	const classes = articlePublicationInfoRecipe({ type });

	const showIcons = type !== 'teaser';

	return (
		<div className={classes.root}>
			{showRevisedDateInsteadOfPublishDate && revisedDate ? (
				<>
					<div className={classes.iconText}>
						{showIcons && <PenIcon width="20px" height="20px" />}
						<Text size={textSize}>
							{formatPublicationDateToEuZurich(
								revisedDateObj,
								includePrefixes ? updatedAtPrefix : '',
								true,
								timezoneTranslations
							)}
						</Text>
					</div>
					<Divider className={classes.divider} />
				</>
			) : null}
			<div className={classes.iconText}>
				{showIcons && <CalendarIcon width="20px" height="20px" />}
				<Text size={textSize}>
					{formatPublicationDateToEuZurich(
						publicationDateObj,
						includePrefixes ? publishedOnPrefix : '',
						true,
						timezoneTranslations
					)}
				</Text>
			</div>
		</div>
	);
};
