import { IArticleTeaserProps } from '@/components/block-templates/ArticlePageTeaserBlock/ArticlePageTeaserBlock.utils';
import { TeaserImage } from '@/components/core/Image/TeaserImage';
import { Link } from '@/components/core/Link/Link';
import { Text } from '@/components/core/Text/Text';
import { ArticlePublicationInfo } from '@/components/shared/Article/ArticlePublicationInfo/ArticlePublicationInfo';
import { getArticleTeaserLinkModeVariant } from '@/components/shared/ArticleTeaser/ArticleTeaser.utils';
import { articleTeaserRecipe } from '@/components/shared/ArticleTeaser/ArticleTeaser.recipe';
import { decodeWhitelistedHTMLEntities } from '@/components/shared/Hero/components/Hero.utils';
import { PersonThumbnailImage } from '@/components/shared/PersonTeaser/components/PersonThumbnail';
import { ArrowRightIcon } from '@/icons/ArrowRightIcon';
import { translate } from '@/utils/i18n/translation-labels/translationLabels';
import { Fragment } from 'react';

export const ArticleTeaser = ({
	id,
	author,
	publishedDate,
	categories,
	title,
	description,
	articleImage,
	articleUrl,
	positionOfTheImage,
	variant,
	displayOption,
	cloudinaryOverrides,
	articleTranslations,
	timezoneTranslations,
}: IArticleTeaserProps) => {
	const classes = articleTeaserRecipe({
		variant,
		imagePosition: positionOfTheImage,
	});

	const linkLabel = translate(articleTranslations, 'article.teaser.readMore');

	return (
		<div className={classes.container} id={id}>
			<div className={classes.contentWrapper}>
				<div className={classes.imageContainer}>
					{articleImage && articleImage.url && (
						<TeaserImage
							src={articleImage.url}
							alt={`${articleImage.alternativeText}`}
							cloudinaryOverrides={cloudinaryOverrides}
							displayOption={displayOption}
						/>
					)}
				</div>
				<div className={classes.contentContainer}>
					<div className={classes.textContainer}>
						<div className={classes.dateAndCategoryContainer}>
							<ArticlePublicationInfo
								includePrefixes={false}
								articleTranslations={articleTranslations}
								timezoneTranslations={timezoneTranslations}
								publicationDate={publishedDate}
								textSize="xs"
							/>
							{categories && categories.length > 0 && (
								<>
									{categories.map((category) => (
										<Fragment key={category}>
											<span className={classes.categorySeparator}>|</span>
											<Text size="xs">{category}</Text>
										</Fragment>
									))}
								</>
							)}
						</div>
						{title && (
							<p className={classes.titleContainer} data-part="article-teaser-title">
								{decodeWhitelistedHTMLEntities(title)}
							</p>
						)}
						{author && (
							<div className={classes.authorContainer}>
								<PersonThumbnailImage
									src={author.photo.url}
									alt={author.fullName}
									cloudinaryOverrides={cloudinaryOverrides}
								/>
								<Text size="xxs">{author.fullName}</Text>
							</div>
						)}
						<Text size="sm">{description}</Text>
					</div>
					<Link
						mode={getArticleTeaserLinkModeVariant(variant)}
						size="sm"
						href={articleUrl}
						rightIcon={<ArrowRightIcon />}
						className={classes.link}
					>
						{linkLabel}
					</Link>
				</div>
			</div>
		</div>
	);
};
