import { FC, SVGAttributes } from 'react';

export const CalendarIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M6.34413 10.1689L25.6457 10.1587C26.0724 10.1587 26.4178 9.81333 26.4178 9.38666C26.4178 8.96 26.0724 8.6146 25.6457 8.6146H23.3702V8.10666C23.3702 7.26349 22.6895 6.58286 21.8463 6.58286C21.0032 6.58286 20.3225 7.26349 20.3225 8.10666V8.6146H11.6673V8.10666C11.6673 7.26349 10.9867 6.58286 10.1435 6.58286C9.30032 6.58286 8.61969 7.26349 8.61969 8.10666V8.6146L6.33905 8.62476C5.91238 8.62476 5.57207 8.97016 5.57207 9.39682C5.57207 9.82349 5.91746 10.1689 6.34413 10.1689Z"
				fill="currentColor"
			/>
			<path
				d="M5.57207 11.9568V25.6559C5.57207 26.0825 5.91746 26.4279 6.34413 26.4279L25.6457 26.4178C26.0724 26.4178 26.4178 26.0724 26.4178 25.6457V11.1746L6.34413 11.1848C5.91746 11.1848 5.57207 11.5302 5.57207 11.9568ZM7.09588 12.7086L24.8889 12.6984V24.8889L7.09588 24.8991V12.7086Z"
				fill="currentColor"
			/>
			<path d="M15.233 14.2222H12.6933V16.7619H15.233V14.2222Z" fill="currentColor" />
			<path d="M19.2965 14.2222H16.7568V16.7619H19.2965V14.2222Z" fill="currentColor" />
			<path d="M23.3651 14.2222H20.8254V16.7619H23.3651V14.2222Z" fill="currentColor" />
			<path d="M11.1645 18.2908H8.62477V20.8305H11.1645V18.2908Z" fill="currentColor" />
			<path d="M15.233 18.2908H12.6933V20.8305H15.233V18.2908Z" fill="currentColor" />
			<path d="M19.3117 18.2908H16.7721V20.8305H19.3117V18.2908Z" fill="currentColor" />
		</svg>
	);
};
