import { FC, SVGAttributes } from 'react';

export const PenIcon: FC<SVGAttributes<SVGElement>> = (props) => {
	return (
		<svg viewBox="0 0 32 32" fill="currentColor" xmlns="http://www.w3.org/2000/svg" {...props}>
			<path
				d="M27.1797 7.35491L24.6552 4.83047C24.066 4.24126 23.101 4.24126 22.5117 4.83047L8.60444 18.7225L7.52762 19.7994L7.42603 19.9009L4.38349 27.6114L12.094 24.5689L27.1797 9.49841C27.4641 9.21396 27.6267 8.83301 27.6267 8.42666C27.6267 8.02031 27.4692 7.63936 27.1848 7.35491H27.1797ZM8.65524 20.9219L11.0629 23.3295L7.08063 24.899L8.65016 20.9168L8.65524 20.9219ZM12.1956 22.3086L9.68127 19.7943L21.4349 8.04571L23.9543 10.5651L12.1956 22.3086Z"
				fill="currentColor"
			/>
		</svg>
	);
};
